import { useEffect, useState } from 'react';
import { AppProps } from 'next/app';
import { useRouter } from 'next/router';
import Link from 'next/link';
import Head from 'next/head';
import { ClerkProvider } from '@clerk/nextjs';
import { Box, ChakraProvider, LightMode } from '@chakra-ui/react';
import PlausibleProvider from 'next-plausible';
import TagManager from 'react-gtm-module';
import {
  BottomCTAV2,
  Fonts,
  Footer,
  // Header,
  MegaMenu,
  SentryLoader,
  SEO
} from '@components';
import { HEADER_HEIGHT } from '@components/layout/header';
import {
  AppAndInstanceSwitcherProvider,
  DocsNavigationProvider,
  IntercomProvider
} from 'context';
import theme from '@theme';
import { isDevelopment } from '@utils';
import { analytics } from 'pages';

import 'focus-visible/dist/focus-visible'; // Remove Chakra's outline caused by clicks
import '@styles/index.css';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { useKoalaIdentify, useSegmentIdentify } from '@hooks';
import { BannerProvider } from '@context/docs-banner';

type PreviewNoticeProps = {
  preview: boolean;
};

type PageIdentifyProps = {
  pageType: 'marketing' | 'docs';
};

function PreviewNotice({ preview }: PreviewNoticeProps) {
  if (!preview) {
    return null;
  }

  return (
    <div className="absolute top-0 w-full py-2 text-center bg-white border-b border-gray-200 z-[9999999]">
      This page is a preview.{' '}
      <Link legacyBehavior href="/api/exit-preview" passHref>
        <a className="underline transition-colors duration-200 hover:text-cyan">
          Click here
        </a>
      </Link>{' '}
      to exit preview mode.
    </div>
  );
}
const docsPages = [
  '/docs',
  '/docs/[category]/[...slug]',
  '/docs/[category]/overview',
  '/docs/[category]/[subcategory]/[...slug]'
];
const noBottomCTA = [
  ...docsPages,
  '/privacy',
  '/startups',
  '/terms',
  '/pricing'
];

const newGradientPages = [
  '/company',
  '/privacy',
  '/terms',
  '/pricing',
  '/',
  '/components'
];

const marketingLandingPage = ['/lp/[slug]'];

export default function MyApp({ Component, pageProps }: AppProps) {
  const { pathname } = useRouter();
  const [identified, setIdentified] = useState<boolean>(false);

  useEffect(() => {
    isDevelopment && console.log('Theme: ', theme);

    process.env.NEXT_PUBLIC_GTM_ID &&
      TagManager.initialize({
        gtmId: process.env.NEXT_PUBLIC_GTM_ID,
        auth: process.env.NEXT_PUBLIC_GTM_AUTH,
        preview: process.env.NEXT_PUBLIC_GTM_PREVIEW
      });
  }, []);

  const UserIdentifyLoader = (): JSX.Element => {
    useSegmentIdentify();
    useKoalaIdentify();
    return <></>;
  };

  const pageSegmentIdentify = (pageType: 'marketing' | 'docs') => {
    if (pageType == 'marketing') {
      analytics.page(`Marketing_Page`);
    } else {
      analytics.page(`Docs_Page`);
    }
    return;
  };

  useEffect(() => {
    if (docsPages.includes(pathname)) {
      pageSegmentIdentify('docs');
    } else {
      pageSegmentIdentify('marketing');
    }
  }, [pathname]);

  return (
    <>
      <Head>
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/favicon/apple-touch-icon.png"
          key="apple-touch-icon"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon/favicon-32x32.png"
          key="favicon-32"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon/favicon-16x16.png"
          key="favicon-16"
        />
        <link rel="manifest" href="/favicon/site.webmanifest" key="manifest" />
        <meta name="theme-color" content="#170f3e" key="theme" />
        <meta name="msapplication-TileColor" content="#170f3e" />
        <link
          rel="mask-icon"
          href="/favicon/safari-pinned-tab.svg"
          color="#170f3e"
        />
        <SentryLoader />
      </Head>

      <Fonts />

      <ClerkProvider>
        <SEO {...pageProps} />
        <PreviewNotice {...pageProps} />
        <IntercomProvider>
          <PlausibleProvider domain={process.env.NEXT_PUBLIC_SITE_URL || ''}>
            <ChakraProvider theme={theme}>
              {typeof window !== undefined && <UserIdentifyLoader />}
              <DocsNavigationProvider>
                <BannerProvider>
                  {docsPages.includes(pathname) ? (
                    <AppAndInstanceSwitcherProvider>
                      <Component {...pageProps} />
                    </AppAndInstanceSwitcherProvider>
                  ) : (
                    <>
                      {/* Prevent non-docs pages from using dark mode */}
                      <LightMode>
                        {marketingLandingPage.includes(pathname) ? (
                          <Component {...pageProps} />
                        ) : (
                          <>
                            {/* <Header /> */}
                            <MegaMenu />

                            <Box
                              overflowX="hidden"
                              overscrollBehavior="contain"
                              pos="relative"
                              top={
                                newGradientPages.includes(pathname)
                                  ? '40px'
                                  : '128px'
                              }
                            >
                              <Component {...pageProps} />
                              {!noBottomCTA.includes(pathname) ? (
                                <BottomCTAV2 />
                              ) : (
                                <Footer />
                              )}
                            </Box>
                          </>
                        )}
                      </LightMode>
                    </>
                  )}
                </BannerProvider>
              </DocsNavigationProvider>
            </ChakraProvider>
          </PlausibleProvider>
        </IntercomProvider>
      </ClerkProvider>
    </>
  );
}
